import React from 'react';
import { useNavigate, Route, Redirect, Navigate, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';

export const PrivateRoute = () => {
    const navigate = useNavigate();
    let urlType = window.location.pathname ? true : false;
    if (localStorage.getItem('refresh_token')) {
        return <Outlet />;
    } else {
        toast.error("You are not authorized to access this location");
        return <Navigate to="/" />;
    }
}
