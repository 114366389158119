import * as yup from "yup";
import {
    VALID_EMAIL,
    EMAIL_REQUIRED,
    PASSWORD_REGEX,
    PASSWORD_REQUIRED,
    CHAR_MIN_6,
} from "./ValidationErrors";

export const LoginSchema = yup.object().shape({
    email: yup
        .string()
        .required(EMAIL_REQUIRED)
        .email(VALID_EMAIL),

    password: yup
        .string()
        .min(6, CHAR_MIN_6)
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/, PASSWORD_REGEX)
        .required(PASSWORD_REQUIRED),

    rememberMe: yup.bool(),
});

export const ChatSchema = yup.object().shape({
    message: yup.string()
});