
import { Col } from "react-bootstrap";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import { Outlet } from "react-router-dom";

const MainLayout = () => {   
    return (
        <main className="mainContentBox">
            <div className="vh-100 d-flex position-relative overflow-hidden">
                <Sidebar />
                <Col xs className="mw-100 h-100 mw-1 bg-dark text-white">
                    <div className="d-flex flex-column h-100 w-100">
                        <Header />
                        <div className="flex-grow-1 overflow-hidden w-100">
                            <Outlet />
                        </div>
                    </div>
                </Col>
            </div>
        </main>
    );
};
export default MainLayout;