import React, { useEffect, useState } from 'react';
import { Button, Form, Image, OverlayTrigger, Stack, Tooltip,Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { ChatSchema } from '../../validations/Auth';
import { IoIosArrowDroprightCircle } from 'react-icons/io';
import { BiSolidMicrophone } from 'react-icons/bi';
import { GrRefresh } from 'react-icons/gr';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import botImage from '../../assets/images/xchat-logo.png';
import botIcon from '../../assets/images/bot-icon.png';
import Loader from '../../components/loader/Loader';
import axios from "axios";
import Header from '../../components/layout/header/Header';
import Sidebar from '../../components/layout/sidebar/Sidebar';


const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;


export default function Dashboard() {
  const [recordedTranscript, setRecordedTranscript] = useState('');
  const [loader, setLoader] = useState(false);
  const [searches, setSearches] = useState("");
  const [ansState, setAnsState] = useState([]);
  const [searchesVoice, setSearchesVoices] = useState("");
  const [transcriptVoi, setTranscriptVoi] = useState("");

  const {
    transcript,
    listening,
    startListening,
    stopListening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    setTranscriptVoi(transcript);
  }, [transcript])



  useEffect(() => {
    setSearchesVoices(searches + transcriptVoi);
  }, [transcriptVoi])


  const handleTranscriptionSubmit = () => {
    resetTranscript();
    setSearches('');
    //setRecordedTranscript('');
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    handleSubmitValClick();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      handleSubmitValClick(event.target.value);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const clickRerording = () => {
    SpeechRecognition.startListening({ continuous: true });
    const stopListeningTimeout = setTimeout(() => {
      SpeechRecognition.stopListening({ continuous: true });
      //resetTranscript();
      SpeechRecognition.stopListening({ continuous: true });
    }, 7000);
    // Clear the timeout if the component unmounts or listening is toggled
    return () => {
      clearTimeout(stopListeningTimeout);
    };
  };

  const stopClickRerording = () => {
    SpeechRecognition.stopListening({ continuous: true });
    setRecordedTranscript('');
  };

  // const sendTranscriptionToGoogleAPI = (transcription) => {
  //   const apiUrl = `https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`;

  //   const requestBody = {
  //     config: {
  //       encoding: 'LINEAR16',
  //       sampleRateHertz: 16000,
  //       languageCode: 'en-US',
  //     },
  //     audio: {
  //       content: Buffer.from(transcription).toString('base64'),
  //     },
  //   };

  //   fetch(apiUrl, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(requestBody),
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log('Transcription result:', data);
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // };

  const handleSubmitValClick = (value) => {

    setLoader(true);
    const finalData = {
      email: "",
    }
    //setAnsState([...ansState, searches]);
    const query = searchesVoice || value;

    //https://api-techtitans.dynamicwebsite.co.in/userml/adminapp
    axios.post("https://api-techtitans.dynamicwebsite.co.in/adminml/adminapp?query=" + query, finalData, {
      headers: {
        "Content-Type": "application/json",
        "query": query,
        //"Accept-Language": "en"
      }
    }).then((response) => {
      console.log(response?.data)
      if (response?.data?.status === "SUCCESS") {
        setLoader(false);
        //response?.data?.data?.answer
        setAnsState([...ansState, response?.data?.data]);
      }
      setLoader(false);
    }).catch((error) => {
      if (error?.response?.data?.status === 400) {
        setLoader(false);
      }
      setLoader(false);
    });
  }


  console.log(ansState);

  return (

    <main className="mainContentBox">
      <div className="vh-100 d-flex position-relative overflow-hidden">
        <Sidebar />
        <Col xs className="mw-100 h-100 mw-1 bg-dark text-white">
          <div className="d-flex flex-column h-100 w-100">
            <Header />
            <div className="flex-grow-1 overflow-hidden w-100">
              <div className="d-flex flex-column h-100 w-100">
                {/* <div className="theme-message-header">
        <Stack direction="horizontal" gap={2} className="gap-xl-4 pb-2 pb-xl-4 ps-xl-5 ps-4 pe-3">
          <Image
            src="https://placehold.co/42x42?text=P"
            alt="User Name"
            width={42}
            height={42}
            className="object-fit-cover rounded-circle"
          />
          <span>Hello, Can you help me ?</span>
        </Stack>
        <div className="py-2 ps-xl-5 ps-4">
          <Stack direction="horizontal" gap={2} className="flex-wrap border border-end-0 border-info px-3 py-3 py-xl-4 rounded-start-3 theme-chat-gradient">
            <Image
              src={botImage}
              alt="User Name"
              width={98}
              height={25}
              fluid
            />
            <span className="fw-medium">Hello! How can I assist you today?</span>
          </Stack>
        </div>

      </div> */}
                <div className="d-flex flex-column flex-grow-1 overflow-auto ps-4 ps-xl-5 py-4 theme-message-body">
                  {ansState.map((value, index) => (
                    // <Stack key={index} direction="horizontal" gap={3} className="border border-end-0 border-info px-3 py-3 py-xl-4 rounded-start-3 theme-chat-gradient mb-3 align-items-start">
                    //   {/* <span>This is test ?</span> */}
                    //   <Image
                    //     src={botIcon}
                    //     alt="User Name"
                    //     width={25}
                    //     height={25}
                    //     fluid
                    //   />
                    //   <span>{value?.answer}</span>
                    // </Stack>
                    <div key={index} className="border border-end-0 border-info px-3 py-3 py-xl-4 rounded-start-3 theme-chat-gradient mb-3 align-items-start">
                      <Stack direction="horizontal" gap={2} className="pb-3">
                        <Image
                          src="https://placehold.co/42x42?text=P"
                          alt="User Name"
                          width={25}
                          height={25}
                          className="object-fit-cover rounded-circle"
                        />
                        <span>{value?.query}</span>
                      </Stack>
                      <Stack direction="horizontal" gap={3}>
                        <Image
                          src={botIcon}
                          alt="User Name"
                          width={25}
                          height={25}
                          fluid
                        />
                        <span>{value?.answer}</span>
                      </Stack>
                    </div>
                  ))}
                </div>

                {/* Footer Code */}
                <div className="theme-message-footer py-4 pe-md-4 pe-3 ps-md-4 ps-3 ps-xl-5">
                  <Form noValidate className="text-center">
                    <Stack direction="horizontal" gap={3} className="position-relative">
                      {
                        ansState?.length > 0 ?
                          <Button className="my-3 text-white px-4 position-absolute bottom-100 start-50 translate-middle-x z-1" variant="secondary">Regenerate Response</Button>
                          : ""
                      }

                      <div className="position-relative flex-fill">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Send a message"
                          name="message"
                          onChange={(e) => setSearchesVoices(e.target.value)}
                          value={searchesVoice}
                        />
                        <Button
                          variant="link"
                          className="end-0 p-0 position-absolute text-primary top-50 translate-middle-y mx-2 z-1"
                          onClick={handleSubmitValClick}
                          // onClick={handleTranscriptionSubmit}
                          onKeyDown={handleKeyPress}
                        >
                          <IoIosArrowDroprightCircle size={32} />
                        </Button>
                      </div>

                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Speech to Text</Tooltip>}
                      >
                        <Button
                          variant="secondary"
                          className={`rounded-pill w-52 h-52 d-inline-flex align-items-center justify-content-center ${listening ? 'theme-pulse' : ''}`}
                          onClick={listening ? stopClickRerording : clickRerording}
                        >
                          {/* {listening ? 'Stop' : 'Start'} */}
                          <BiSolidMicrophone size={22} />
                        </Button>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Reset</Tooltip>}
                      >
                        <Button
                          variant="secondary"
                          className="rounded-pill w-52 h-52 d-inline-flex align-items-center justify-content-center"
                          onClick={handleTranscriptionSubmit}
                        >
                          <GrRefresh size={22} />
                        </Button>
                      </OverlayTrigger>

                    </Stack>
                  </Form>
                </div>
                {
                  loader && <Loader />
                }
              </div>
            </div>
          </div>
        </Col>
      </div>
    </main>


  );
}
