import React from 'react'
import { Accordion, Image, Stack } from 'react-bootstrap'
import botImage from '../../../../assets/images/bot-icon.png';

const accordionData = [
    {
        "id": 1,
        "title": "Today",
        "content": `
        <p class='text-truncate small mb-2'>Lorem Ipsum is simply dummy lorem Ipsum is simply dummy</p>
        <p class='text-truncate small mb-2'>Lorem Ipsum is simply dummy lorem Ipsum is simply dummy</p>
        <p class='text-truncate small mb-2'>Lorem Ipsum is simply dummy lorem Ipsum is simply dummy</p>
        `
    },
    {
        "id": 2,
        "title": "Yesterday",
        "content": `
        <p class='text-truncate small mb-2'>Lorem Ipsum is simply dummy lorem Ipsum is simply dummy</p>
        <p class='text-truncate small mb-2'>Lorem Ipsum is simply dummy lorem Ipsum is simply dummy</p>
        `
    },
    {
        "id": 3,
        "title": "Last 7 days",
        "content": `
        <p class='text-truncate small mb-2'>Lorem Ipsum is simply dummy lorem Ipsum is simply dummy</p>
        <p class='text-truncate small mb-2'>Lorem Ipsum is simply dummy lorem Ipsum is simply dummy</p>
        <p class='text-truncate small mb-2'>Lorem Ipsum is simply dummy lorem Ipsum is simply dummy</p>
        `
    }
]


const ChatPanel = ({ chatPanelShow }) => {
    return (
        <div className={`theme-panel-cover text-white d-flex flex-column h-100 top-0 z-2 overflow-hidden text-nowrap ${chatPanelShow === true ? 'active' : ''}`}>
            <Stack direction="horizontal" gap="2" className="theme-panel-header pt-3 px-3 pb-2">
                <Image className="me-1" src={botImage} fluid width={32} height={32} />
                <h3 className="mb-0 fw-bold">XCHAT</h3>
            </Stack>
            <div className="p-3 flex-fill overflow-auto">
                <Accordion defaultActiveKey={[1]} alwaysOpen>
                    {accordionData.map((item) => {
                        return (
                            <Accordion.Item eventKey={item.id} key={item.id} className="pb-3">
                                <Accordion.Header>
                                    {item.title}
                                </Accordion.Header>
                                <Accordion.Body dangerouslySetInnerHTML={{ __html: item.content }}></Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </div>
        </div>

    )
}

export default ChatPanel