import React, { useState, useEffect } from 'react';
import { apiPath } from "../api";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Navigate } from "react-router-dom";
import Login from '../pages/auth/login/Login';
import Dashboard from '../pages/dashboard/Dashboard';
import { PrivateRoute } from '../PrivateRoute';
import MainLayout from '../components/layout/MainLayout';


import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";

function CustomRoute() {
    const navigate = useNavigate();
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route exact path='/' element={<PrivateRoute />}>
                <Route path="/app/dashboard" element={<Dashboard />} />
            </Route>
        </Routes>
    )
}

export default CustomRoute;
