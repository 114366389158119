import React from 'react'
import './header.scss'
import { Button, Stack } from 'react-bootstrap'
import { LuFileUp } from "react-icons/lu";
import { BsFillChatLeftTextFill, BsFillPlusCircleFill } from "react-icons/bs";
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header>
      <div className="theme-header-top py-3 d-none d-md-block"></div>
      <Stack direction="horizontal" gap={3} className="gap-xl-5 justify-content-end px-2 py-3">
        {/* <Button className="text-decoration-none fw-bold px-0" variant="link">
          <LuFileUp size={24} className="me-2 align-bottom" />
          <span className="text-white">EXPORT CHAT</span>
        </Button> */}
        <Link to='/app/dashboard' className="text-decoration-none fw-bold px-0 btn btn-link">
          <span className="position-relative me-2">
            <BsFillChatLeftTextFill size={24} />
            <BsFillPlusCircleFill size={16} className="text-white position-absolute translate-middle top-0 start-100 z-1 mt-n1" />
          </span>
          <span className="text-white">NEW CHAT</span>
        </Link>
      </Stack>
    </header>
  )
}

export default Header