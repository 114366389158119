import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'
import './App.scss';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes';
import { Loader } from './components/common/Loader';

import CustomRoute from './routes/CustomRoute';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <>
      <Suspense
        fallback={
          <Loader isLoading={true} />
        }
      >
        {/* <BrowserRouter>
          <AppRoutes />
        </BrowserRouter> */}
        <React.Fragment>
          <Router>
            <CustomRoute />
          </Router>
        </React.Fragment>
      </Suspense>
      <ToastContainer
        role="alert"
      />
    </>

  );
}

export default App;
