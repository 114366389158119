import React, { useState } from 'react'
import { Button, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

export const InputText = ({
    controlId,
    label,
    placeholder,
    handleChange,
    handleBlur,
    errorsField,
    touched,
    value,
    size,
    name,
    type,
    as,
    rows,
    togglePassword
}) => {

    const [passwordShow, setPasswordShow] = useState(togglePassword);
    const passwordToggleHandler = () => {
        setPasswordShow(!passwordShow)
    }

    return (
        <Form.Group controlId={controlId} className="w-100">
            {label ? (
                <Form.Label className="mb-1">
                    <small className="pe-1">{label}</small>
                </Form.Label>
            ) : ''}
            <div className="position-relative">
                <InputGroup className="z-0">
                    <Form.Control
                        name={name}
                        size={size}
                        value={value}
                        type={type === 'password' ? (passwordShow ? 'password' : 'text') : 'text'}
                        placeholder={placeholder}
                        as={as}
                        rows={rows}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={!!errorsField && touched}
                    />
                    {togglePassword ?
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{passwordShow ? 'Show Password' : 'Hide Password'}</Tooltip>}
                        >
                            <Button variant="secondary" onClick={passwordToggleHandler}>
                                {passwordShow ? <FaEye /> : <FaEyeSlash />}
                            </Button>
                        </OverlayTrigger> : ''
                    }
                </InputGroup>
            </div>
            <Form.Control.Feedback type="invalid" className={errorsField && touched ? 'd-block' : 'd-none'}>
                {errorsField}
            </Form.Control.Feedback>
        </Form.Group>
    )
}
