import React, { useState } from 'react'
import { Button, Dropdown, Nav, Navbar } from 'react-bootstrap'
import { FiMessageSquare } from "react-icons/fi";
import { BsFillBookmarksFill } from "react-icons/bs";
import { BiSolidCog } from "react-icons/bi";
import './sidebar.scss';
import ChatPanel from './panels/ChatPanel';
import { useNavigate, Route, Navigate, Link } from 'react-router-dom';

const Sidebar = () => {
  const [chatPanelShow, setChatPanelShow] = useState(false);

  const chatPanelHandler = () => {
    setChatPanelShow(!chatPanelShow)
  }

  console.log('chatPanelShow', chatPanelShow)


  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("refresh_token");
    sessionStorage.removeItem("token");
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <div className="col-auto d-inline-flex position-relative">
      <Navbar
        bg="dark"
        data-bs-theme="dark"
        className="h-100 align-items-start flex-column theme-sidebar-navbar shadow-lg position-relative z-3"
      >
        <Nav className="flex-column py-2 gap-1">
          <Button onClick={chatPanelHandler} className={`py-3 ${chatPanelShow === true ? 'active' : ''}`} variant="link">
            <FiMessageSquare size={24} />
          </Button>
          {/* <Button className="py-3" variant="link">
            <BsFillBookmarksFill size={24} />
          </Button> */}
        </Nav>

        <Dropdown className="py-2 mt-auto" drop="up">
          <Dropdown.Toggle variant="link" className="p-1">
            <BiSolidCog size={24} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {/* <Dropdown.Item href="/">Settings</Dropdown.Item> */}
            <Dropdown.Item href="/" onClick={logout} >Logout</Dropdown.Item>
          </Dropdown.Menu>

        </Dropdown>
      </Navbar>
      <ChatPanel chatPanelShow={chatPanelShow} />
    </div>
  )
}

export default Sidebar