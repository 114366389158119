import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Container, Form, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { LoginSchema } from '../../../validations/Auth';
import { InputText } from '../../../components/common/InputText';
import { LOGIN_SUCCESS } from '../../../validations/ValidationErrors';
import loginBanner from '../../../assets/images/login-banner.jpg';
import axios from "axios";
import Loader from '../../../components/loader/Loader';
import { apiPath } from '../../../api';

const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loader, setLoader] = useState(false);

    const [submitted, setSubmitted] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [validPassword, setValidPassword] = useState(true);
    const [credentialError, setCredentialError] = useState("");


    useEffect(() => {
        localStorage.getItem('refresh_token') ? navigate('/app/dashboard') : navigate('/');
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        let error = false;
        if (email === "" || password === "") {
            error = true;
            setValidEmail(true)
        }

        if (email !== "") {
            if (
                !new RegExp(
                    /(^(?!.*__.*)[a-zA-z0-9]{2,253}(_?)[a-zA-Z0-9]+(?:\.[a-zA-Z0-9!#$%&*+\/=?^`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9]*[a-zA-Z0-9])?$)/g
                ).test(email)
            ) {
                setValidEmail(false);
                setCredentialError("");
                error = true;
            } else {
                setValidEmail(true);
            }
        }

        if (error == true) {
            return;
        }
        setLoader(true);

        const finalData = {
            email: email,
            password: password
        }
        // API Calls
        axios.post(apiPath.login, finalData, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Accept-Language": "en"
            }
        }).then((response) => {
            console.log(response?.data?.data?.token);
            if (response?.data?.status === 400) {
                toast.error(response?.data?.message);
                setCredentialError(response?.data?.message);
                setLoader(false);
            }
            if (response?.data?.status === 200) {
                toast.success("Your account login successfully.");
                console.log(response.data?.refresh_token);
                localStorage.setItem("refresh_token", response?.data?.data?.token);
                localStorage.setItem("token", response?.data?.data?.token);
                navigate('/app/dashboard');
            }
        }).catch((error) => {
            if (error?.response?.data?.status === 400) {
                toast.error(error?.response?.data?.message);
                setCredentialError(error?.response?.data?.message);
                setLoader(false);
            }
        });
    }

    return (
        <Card className="min-vh-100 w-100 p-sm-5 border-0 rounded-0 z-1">
            <Container className="my-auto">
                <Col md={8} xl={5} xxl={4} className="ms-md-auto my-auto p-2 text-center text-md-end">
                    <div className="bg-body border border-light-subtle d-inline-block p-4 rounded-4 shadow-lg w-100 mw-450 text-start">
                        <div className="p-sm-2">
                            <h2 className="mb-4 mt-3 fw-medium">Log In</h2>
                            <p className="showerror" style={{color:'red'}}>{credentialError}</p>
                            <Form.Group className="mb-4 pb-2">
                                <InputText
                                    controlId="email"
                                    label='Email Address'
                                    placeholder="Enter Email Address"
                                    handleChange={(e) => setEmail(e.target.value)}
                                />
                                {submitted && email === "" && (
                                    <p className="showerror" style={{color:'red'}}>Please enter Email</p>
                                )}
                                {submitted && !validEmail && email !== "" && (
                                    <p className="showerror" style={{color:'red'}}> Email is Invalid </p>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-4 pb-2">
                                <InputText
                                    controlId="password"
                                    label='Password'
                                    placeholder="Enter Password"
                                    type="password"
                                    handleChange={(e) => setPassword(e.target.value)}
                                />
                                {submitted && password === "" && (
                                    <p className="showerror" style={{color:'red'}}>Please enter Password</p>
                                )}
                            </Form.Group>
                            <Stack className="flex-wrap" direction="horizontal" gap={3}>
                                <Form.Group className="me-auto">
                                    <Form.Check
                                        id="remember-me"
                                        name="rememberMe"
                                        label="Remember me"
                                    />
                                </Form.Group>
                                <Button className="text-white fs-5 fw-medium px-5" onClick={handleSubmit} variant="primary" type="button">
                                    Submit
                                </Button>
                            </Stack>
                        </div>
                    </div>
                </Col>
            </Container>
            <Card.ImgOverlay className="p-0 z-n1 rounded-0 user-select-none pe-none">
                <Card.Img className="rounded-0 w-100 h-100 object-fit-cover" src={loginBanner} alt="Login Banner" />
            </Card.ImgOverlay>
            {
                loader && <Loader />
            }
        </Card>
    )
}
export default Login;
